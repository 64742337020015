import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

const Root = styled(motion.div)`
  margin: 0 auto;
  max-width: 55em;
`

export const NewsLetter: React.FunctionComponent = () => {
  return (
    <Root
      transition={{ duration: 1, delay: 2 }}
      initial={{ opacity: 0, y: 100 }}
      animate={{ opacity: 1, y: 0 }}
    >
      <iframe
        src="https://tengable.substack.com/embed"
        width="100%"
        height="320"
        frameBorder="0"
        scrolling="no"
        title="newsletter signup"
      />
    </Root>
  )
}
