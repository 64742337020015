import styled from 'styled-components'
import { motion } from 'framer-motion'
import { colors } from '@/theme'
import { getTextColor } from '@/utils/getTextColor'

export const ButtonLink = styled(motion.a)`
  display: inline-block;
  padding: 1.6em 1.6em;
  border-radius: 8px;
  font-size: 1.4em;
  font-weight: 500;
  text-decoration: none;
  background-color: ${({ bgColor = colors.primary }: { bgColor?: string }) =>
    bgColor};
  color: ${({ bgColor = colors.primary }: { bgColor?: string }) =>
    getTextColor(bgColor)};

  transition: all 0.3s;
  &:active {
    opacity: 0.8;
  }
  &:hover {
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.75);
  }
`
