import React from 'react'
import { PageProps } from 'gatsby'
import { PageLayout } from '@/components/pageLayout'
import { PricingHeader } from '@/components/pricingHeader'
import Grid from '@material-ui/core/Grid'
import { Container } from '@/components/container'
import styled from 'styled-components'
import { NewsLetter } from '@/components/newsLetter'

const SectionWrapper = styled.div`
  padding: 32px 0;
`

const Spacer = styled.div`
  height: ${({ space = 64 }: { space?: number }) => `${space}px`};
`
const Home: React.FC<PageProps> = ({ path }) => {
  return (
    <PageLayout pathname={path}>
      <PricingHeader />
      <Spacer />
      <Container>
        <Grid container spacing={2}>
          <Grid item xs ={12}>
            <SectionWrapper>
              <NewsLetter />
            </SectionWrapper>
          </Grid>
        </Grid>
      </Container>
    </PageLayout>
  )
}
export default Home
