import React from 'react'
import { ButtonLink } from './buttonLink'

interface DemoButtonProps {
  bgColor?: string
  hintTextColor?: string
}
export const DemoButton: React.FunctionComponent<DemoButtonProps> = ({
  bgColor,
  hintTextColor,
}) => (
  <>
    <ButtonLink bgColor={bgColor} href="https://app.tengable.com/demo">
      Try Our <strong>FREE</strong> Demo
    </ButtonLink>
    <p style={{ color: hintTextColor }}>No credit card, or account required.</p>
  </>
)
