import styled from 'styled-components'

export const reverseRadiusHeight = 50
export const reverseRadiusWidth = reverseRadiusHeight * 2
export const ReverseRadius = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  width: 60%;
  max-width: 600px;
  /* margin: 0 auto; */
  z-index: 1;
  background-color: rgba(255, 255, 255);
  padding-right: ${({ padding = `0px` }: { padding?: string }) => padding};
  padding-left: ${({ padding = `0px` }) => padding};
  position: relative;

  &:before,
  &:after {
    content: '';
    position: absolute;

    height: ${reverseRadiusHeight}px;
    width: ${reverseRadiusWidth}px;

    bottom: 0;
  }

  &:after {
    right: -${reverseRadiusWidth}px;

    border-radius: 0 0 0 ${reverseRadiusHeight}px;
    box-shadow: -${reverseRadiusHeight}px 0 0 0 #fff;
  }

  &:before {
    left: -${reverseRadiusWidth}px;
    border-radius: 0 0 ${reverseRadiusHeight}px 0;
    box-shadow: ${reverseRadiusHeight}px 0 0 0 #fff;
  }
`
